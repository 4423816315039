<template>
  <SingleArticleTeaser
    :title="title"
    :description="description"
    :article-link="article_link"
    :size="size"
    :image="imageUrl"
    :headline-level="headlineLevel"
    :image-alt-text="imageAltText"
    :button-color="buttonColor"
    :button-text="buttonText"
    :src-set="srcSet"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      title() {
        return this.getValue("title")
      },
      headlineLevel() {
        return this.getIngredient("title")?.level || 3
      },
      description() {
        return this.getValue("description")
      },
      article_link() {
        return this.getValue("article_link")
      },
      size() {
        return this.getValue("size")
      },
      image() {
        return this.getIngredient("image") || {}
      },
      imageUrl() {
        return this.image.value
      },
      srcSet() {
        return this.image.srcset
      },
      imageAltText() {
        return this.image.altText
      },
      buttonColor() {
        return this.getValue("button_color")
      },
      buttonText() {
        return this.getValue("button_text")
      },
    },
  }
</script>
