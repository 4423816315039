<template>
  <Container space-before space-after>
    <div v-if="size === 'small'" class="single-small-article-teaser">
      <div class="single-small-article-teaser-content">
        <Headline :level="headlineLevel" :size="4">
          {{ title }}
        </Headline>
        <Button
          :color="buttonColor"
          :rounded-corners="false"
          size="small"
          :to="articleLink"
        >
          {{ buttonText }}
        </Button>
      </div>
      <picture>
        <source
          v-for="imgTypes in srcSetPerType"
          :key="imgTypes.type"
          :type="imgTypes.type"
          :srcset="imgTypes.srcsetstring"
          sizes="160px"
        />

        <img
          class="single-small-article-teaser-image"
          :src="image"
          role="none"
          :alt="imageAltText"
          loading="lazy"
        />
      </picture>
    </div>

    <div v-else class="single-article-teaser">
      <picture>
        <source
          v-for="imgTypes in srcSetPerType"
          :key="imgTypes.type"
          :type="imgTypes.type"
          :srcset="imgTypes.srcsetstring"
          sizes="(max-width: 959px) 100vw, 66vw, (min-width: 1440px) 888px"
        />

        <img
          :src="image"
          :alt="imageAltText"
          class="single-article-teaser-image"
          loading="lazy"
        />
      </picture>

      <div class="single-article-teaser-content">
        <Headline no-margin :level="headlineLevel">{{ title }}</Headline>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="single-article-teaser-paragraph" v-html="description" />

        <Button :color="buttonColor" size="medium" :to="articleLink">
          {{ buttonText }}
        </Button>
      </div>
    </div>
  </Container>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"
  import Container from "~/components/Container"
  import Headline from "~/components/Headline"
  import Button from "~/components/Button"

  export default {
    components: { Headline, Container, Button },
    mixins: [createFormatArray],
    props: {
      title: {
        type: String,
        default: null,
      },
      headlineLevel: {
        type: [String, Number],
        default: 3,
      },
      image: {
        type: String,
        default: null,
      },
      srcSet: {
        type: Array,
        default: () => [],
      },
      description: {
        type: String,
        default: null,
      },
      articleLink: {
        type: String,
        default: null,
      },
      buttonColor: {
        type: String,
        default: "blue",
      },
      buttonText: {
        type: String,
        default: "Read Now",
      },
      size: {
        type: String,
        default: null,
      },
      imageAltText: {
        type: String,
        default: null,
      },
    },

    computed: {
      srcSetPerType() {
        return this.createFormatArray(this.srcSet)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .single-article-teaser {
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
    height: $space-l * 7;
    @include viewport("mini") {
      height: auto;
    }
  }
  .single-article-teaser-image {
    width: 100%;
    object-fit: cover;
    height: 100%;
    @include viewport("mini") {
      aspect-ratio: 16/9;

      border-radius: $border-radius;
    }
  }

  .single-article-teaser-content {
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    flex-direction: column;
    padding: $space-m;
    padding-bottom: 0;
    background: white;
    left: $space-m;
    right: 40%;
    position: absolute;
    align-items: flex-start;
    bottom: 0;
    @include viewport("m", "sm", "md") {
      right: $space-m;
    }
    @include viewport("mini") {
      padding: $space-s 0 $space-m;
      position: static;
    }
  }

  .single-article-teaser-paragraph {
    margin-bottom: $space-s;
  }

  /* Small version */
  .single-small-article-teaser {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .single-small-article-teaser-image {
    width: $space-l * 2;
    height: 100%;
    box-shadow: $shadow;
    border-radius: $border-radius;
  }
</style>
